<template>
  <div class="container">
  
    <div class="row justify-content-end" v-if="!loading && church_id">
      <router-link :to="{ name: 'admin.speakers.create', query: {church_id: church_id}}" class="btn btn-xs btn-primary mg-b-15 float-right">{{ $t('speakers.create')}}</router-link>
    </div>
    <div class="row">
      <select @change="onChange" class="form-control mg-b-25" v-model="church_id">
        <option value="">{{ $t('select one') }}</option>
        <option v-for="church in churches" v-bind:key="church.id" :value="church.id">{{church.name}}</option>
      </select>
    </div>
    <div class="placeholder-paragraph aligned-centered" v-if="loading">
      <div class="line"></div>
      <div class="line"></div>
    </div>
    <div class="row" v-if="!loading && church_id">
      <table class="table" >
        <thead>
        <tr>
          <th scope="col">Name</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="speaker in speakers" v-bind:key="speaker.id">
          <td>
            <router-link :to="{name: 'admin.speakers.edit', params: {id: speaker.id}, query: {church_id: church_id}}">{{ speaker.name }}</router-link>
          </td>
          <td class="text-right">
            <fa :icon="['fa', 'trash-alt']" v-on:click="deleteSpeaker(speaker)"/>
          </td>
        </tr>
        </tbody>
      </table>

      <Pagination v-bind:meta="meta" v-on:pageChanged="onPageChanged"></Pagination>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import axios from 'axios'
  import Pagination from '~/components/Pagination'

  export default {
    middleware: 'auth',
    components: {
      Pagination 
    },
    data: () => ({
      loading: true,
      church_id: "",
      meta: {},
      limit: 30,
      page: 1,
      speakers: []
    }),
    computed: mapGetters({
      churches: 'admin/churches',
      speakers: 'admin/speakers',
    }),
    async created() {
      if (this.churches.length == 0) {
        await this.$store.dispatch('admin/fetchChurches');
      }
      if (this.speakers.length == 0) {
        this.$store.dispatch('admin/fetchSpeakers');  
      }
      this.refresh();
    },
    watch: {
      // call again the method if the route changes
      '$route': 'refresh'
    },
    methods: {
      refresh() {
        if (this.$route.query.church_id) {
          this.church_id = this.$route.query.church_id;
          this.loading = true;
          this.$store.dispatch('admin/fetchSpeakers', {limit: this.limit, page: this.page, church_id: this.church_id})
            .then((data) => { 
              this.loading = false;
              this.meta = data.meta;
              this.speakers = data.speakers;
            });
        } else {
          this.loading = false;
          this.church_id = "";
          this.speakers = [];
          this.meta = {};
          this.page = 1;
        }
      },
      onChange(event) {
        this.$router.push({ name: 'admin.speakers.index', query: {church_id: event.target.value }});
      },
      onPageChanged (page) {
        this.page = page;
        this.refresh();
      },
      async deleteSpeaker(speaker) {   
        try{
          await axios.delete('/api/v1/admin/speakers/' +  speaker.id);
          this.refresh();
        } catch (e) {
          // this.$router.push({name: 'accounts.church.invite'});
        }
      }
    }
  }
</script>
